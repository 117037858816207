var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"app":"","floating":"","right":"","temporary":""},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-list',[_vm._l((_vm.topLevelMenu),function(item){return [(!item.hasSub && item.visible)?_c('v-list-item',{key:item.id,attrs:{"to":item.path},on:{"click":function($event){return item.click($event, item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"left":"","color":item.color}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):_c('v-list-group',{key:item.id,attrs:{"prepend-icon":item.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true)},[_vm._l((_vm.secondLevelMenu),function(subitem){return [(subitem.type === 'divider' && item.id === subitem.parentId && subitem.visible)?_c('v-divider',{key:item.id+subitem.id}):(item.id === subitem.parentId && subitem.visible)?_c('v-tooltip',{key:item.id+subitem.id,attrs:{"open-delay":500,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","disabled":subitem.disabled,"href":subitem.href,"target":subitem.href ? '_blank' : undefined},on:{"click":function($event){subitem.click ? subitem.click($event, subitem) : null}}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(_vm._s(subitem.title))]),_c('v-list-item-icon',[(subitem.badge)?_c('v-badge',{attrs:{"overlap":"","icon":subitem.badge,"color":subitem.badgeColor}},[_c('v-icon',{attrs:{"color":subitem.disabled ? 'grey lighten-1': subitem.color}},[_vm._v(_vm._s(subitem.icon))])],1):_c('v-icon',{attrs:{"color":subitem.disabled ? 'grey lighten-1': subitem.color}},[_vm._v(_vm._s(subitem.icon))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(subitem.tooltip ? subitem.tooltip : subitem.title))])]):_vm._e()]})],2)]})],2)],1),_c('v-app-bar',{attrs:{"elevation":"0","app":"","dense":""}},[_c('v-toolbar-title',[_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"to":"/"}},[_c('v-flex',[_c('v-img',{attrs:{"src":require('@/assets/itus-logo.svg'),"width":"133","height":"36","alt":_vm.appTitle,"position":"left center"}})],1)],1)],1),_c('v-spacer'),(_vm.$store.getters.isUserLoaded)?[_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","text":"","input-value":undefined},on:{"click":function($event){$event.stopPropagation();return _vm.upgradeClick.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"accent"}},[_vm._v("fas fa-arrow-circle-up")]),_vm._v(" Upgrade ")],1)]}}],null,false,1094246964)},[_c('span',[_vm._v("For even more functionality upgrade today!")])]),_vm._l((_vm.topLevelMenu),function(item){return [(!item.hasSub && item.visible)?_c('v-tooltip',{key:item.id,attrs:{"bottom":"","open-delay":500,"disabled":item.titleInToolbar},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down",attrs:{"tile":"","text":"","input-value":undefined,"to":item.path},on:{"click":function($event){return item.click($event, item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":item.color}},[_vm._v(_vm._s(item.icon))]),(item.titleInToolbar)?[_vm._v(_vm._s(item.title))]:_vm._e()],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]):_vm._e(),(item.hasSub && item.title && item.titleInToolbar && item.visible)?_c('v-menu',{key:item.id,staticClass:"hidden-sm-and-down",attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-sm-and-down",attrs:{"tile":"","text":""}},on),[_c('v-icon',{attrs:{"left":"","color":item.color}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)]}}],null,true)},[_c('v-list',{staticClass:"hidden-sm-and-down"},[_vm._l((_vm.secondLevelMenu),function(subitem){return [(subitem.type === 'divider' && item.id === subitem.parentId && subitem.visible)?_c('v-divider',{key:item.id+subitem.id}):(item.id === subitem.parentId && subitem.visible)?_c('v-tooltip',{key:subitem.id,attrs:{"open-delay":500,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"hidden-sm-and-down",attrs:{"disabled":subitem.disabled,"href":subitem.href,"target":subitem.href ? '_blank' : undefined},on:{"click":function($event){subitem.click ? subitem.click($event, subitem) : null}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[(subitem.badge)?_c('v-badge',{attrs:{"overlap":"","icon":subitem.badge,"color":subitem.badgeColor}},[_c('v-icon',{attrs:{"left":"","color":subitem.disabled  ? 'grey lighten-1': subitem.color}},[_vm._v(_vm._s(subitem.icon))])],1):_c('v-icon',{attrs:{"left":"","color":subitem.disabled  ? 'grey lighten-1': subitem.color}},[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(subitem.title)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(subitem.tooltip ? subitem.tooltip : subitem.title))])]):_vm._e()]})],2)],1):_vm._e(),(item.hasSub && (!item.title || !item.titleInToolbar) && item.visible)?_c('v-menu',{key:item.id,staticClass:"hidden-sm-and-down",attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":500,"disabled":item.titleInToolbar},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-sm-and-down",attrs:{"rounded":"","text":"","icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]}}],null,true)},[_c('v-list',{staticClass:"hidden-sm-and-down"},[_vm._l((_vm.secondLevelMenu),function(subitem){return [(subitem.type === 'divider' && item.id === subitem.parentId && subitem.visible)?_c('v-divider',{key:item.id+subitem.id}):(item.id === subitem.parentId && subitem.visible)?_c('v-tooltip',{key:subitem.id,attrs:{"open-delay":500,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"hidden-sm-and-down",attrs:{"disabled":subitem.disabled,"href":subitem.href,"target":subitem.href ? '_blank' : undefined},on:{"click":function($event){subitem.click ? subitem.click($event, subitem) : null}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[(subitem.badge)?_c('v-badge',{attrs:{"overlap":"","icon":subitem.badge,"color":subitem.badgeColor}},[_c('v-icon',{attrs:{"left":"","color":subitem.disabled  ? 'grey lighten-1': subitem.color}},[_vm._v(_vm._s(subitem.icon))])],1):_c('v-icon',{attrs:{"left":"","color":subitem.disabled  ? 'grey lighten-1': subitem.color}},[_vm._v(_vm._s(subitem.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(subitem.title)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(subitem.tooltip ? subitem.tooltip : subitem.title))])]):_vm._e()]})],2)],1):_vm._e()]}),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","text":"","icon":""}},Object.assign({}, tooltip, menu)),[_c('UserAvatar',{attrs:{"firstName":_vm.firstName,"lastName":_vm.lastName,"size":36}})],1)]}}],null,true)},[_vm._v(" Account ")])]}}],null,false,3358416072)},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('UserAvatar',{attrs:{"firstName":_vm.firstName,"lastName":_vm.lastName,"size":80}})],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.fullName)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.user.email)+" ")])],1)],1)],1),_c('v-list',[_c('v-list-item',{key:"account",on:{"click":function($event){return _vm.$router.push('/account')}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("person")]),_vm._v(" Manage your account ")],1)],1),_c('v-list-item',{key:"signout",on:{"!click":function($event){$event.stopPropagation();return _vm.signOut.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("exit_to_app")]),_vm._v(" Sign out ")],1)],1)],1)],1)],2),_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",nativeOn:{"click":function($event){$event.stopPropagation();_vm.sideNav = !_vm.sideNav}}})]:_vm._e()],2),_c('v-main',{staticStyle:{"width":"100%","height":"calc(100% - 70px)"}},[_c('router-view',{key:_vm.$route.fullPath,staticClass:"pa-0 ma-0"}),_c('SessionTimeoutDialog',{ref:"sessionTimeoutDialog"}),_c('NewVersionAvailableDialog',{ref:"newVersionAvailableDialog"}),_c('Confirm',{ref:"confirm"}),_c('UpgradeDialog',{ref:"upgradeDialog"})],1),_c('VideoDialog',{attrs:{"videos":_vm.guide},on:{"close":_vm.closeGuide},model:{value:(_vm.showGuide),callback:function ($$v) {_vm.showGuide=$$v},expression:"showGuide"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }