import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { UserModel } from '@/user-model'
import analyses from './modules/analyses'
import taxonomy from './modules/taxonomy'
import preferences from './modules/preferences'
import buffer from 'buffer'
import { buildApiUrl } from '@/components/api-url-builder'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'

const Buffer = buffer.Buffer

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    session: '',
    sessionExpiration: '',
    user: null,
    initialized: false,
    sessionTimeoutShown: false,
    routeAfterLogin: null,
    dirty: false,
    guide: null,
    videoPath: null,
    version: null
  }
}

const store = new Vuex.Store({
   modules: {
    analyses,
    taxonomy,
    preferences
  },
  state: getDefaultState(),
  mutations: {
    initialized(state, { session }) {
      state.session = session
      state.initialized = true
    },
    login(state, { session }) {
      localStorage.setItem("session", session)
      state.session = session
    },
    loadUser(state, user) {
      state.user = user
    },
    sessionTimeoutShown(state) {
      state.sessionTimeoutShown = true
    },
    resetState(state) {
      localStorage.removeItem("session")
      localStorage.removeItem("sessionExpiration")

      //  Reset the state of the store - merge so that we don't lose observers
      Object.assign(state, getDefaultState());
    },
    routeAfterLogin(state, nextRoute) {
      state.routeAfterLogin = nextRoute
    },
    setGuide(state, guide) {
      state.guide = guide
    },
    clearGuide(state) {
      state.guide = null
    },
    setVersion(state, version) {
      state.version = version
    },
    setTimeLeft(state, timeLeft) {
      localStorage.setItem("sessionExpiration", timeLeft)

      state.sessionExpiration = timeLeft
      state.sessionTimeoutShown = false
    },
    setDirty(state, dirty) {
      state.dirty = dirty
    },
    setVideoPath(state, path) {
      state.videoPath = path
    }
  },
  actions: {
    async initialize({ commit, dispatch }) {
      try {
        if (store.state.session && store.state.user) {
          if(!store.state.sessionExpiration || (store.state.sessionExpiration && DateTime.fromISO(store.state.sessionExpiration, { zone: 'utc' }) <= DateTime.utc()))
            await dispatch('resetState')

          return
        }

        let session = ''
        let sessionExpiration = ''
        // get properties from local storage if they exist
        if (Object.hasOwnProperty.call(localStorage, 'session'))
          session = localStorage.getItem('session')

        if (Object.hasOwnProperty.call(localStorage, 'sessionExpiration'))
          sessionExpiration = localStorage.getItem('sessionExpiration')

        // update the default headers before we validate if the session is still good
        if (session && sessionExpiration && DateTime.fromISO(sessionExpiration, {zone: 'utc'}) > DateTime.utc()) {
          commit('initialized', { session })
          commit('setTimeLeft', sessionExpiration)

          axios.defaults.headers.common['Authorization'] = session

          // now trigger a keep alive for the session
          await dispatch('keepSessionAlive')
          if (store.state.session && store.state.sessionExpiration && DateTime.fromISO(store.state.sessionExpiration, {zone: 'utc'}) > DateTime.utc() )
            await dispatch('loadUser')
        }
      } catch(err) {
        await dispatch('resetState')
      }
    },
    routeAfterLogin({ commit }, nextRoute) {
      commit('routeAfterLogin', ((!nextRoute || nextRoute.path === '/signin' || nextRoute.path === '/') ? null : nextRoute))
    },
    async login({ commit, dispatch }, user) {
      let routeAfterLogin = store.state.routeAfterLogin
      try {        
        await dispatch('resetState')
        await dispatch('routeAfterLogin', routeAfterLogin)

        const resp = await axios.post(buildApiUrl('/api/login'), user )
        if(!resp || !resp.data || !resp.data.session || !resp.data.user || !resp.data.expires)
          throw new Error('Invalid login.')

        const session = Buffer.from(resp.data.session).toString('base64')
        const userModel = new UserModel(resp.data.user)
        axios.defaults.headers.common['Authorization'] = session

        commit('login', { session })
        commit('setTimeLeft', resp.data.expires)
        commit('loadUser', userModel)
      } catch(err) {
        await dispatch('resetState')
        await dispatch('routeAfterLogin', routeAfterLogin)
        throw err
      }
    },
    async loadUser({ commit }) {
      const resp = await axios.get(buildApiUrl(`/api/account?t=${(new Date().getTime())}`))
      if(!resp || !resp.data)
        throw new Error('Error loading user.')

      const user = new UserModel(resp.data)
      commit('loadUser', user)
    },
    async checkCurrentVersion({ commit }) {
      const resp = await axios.get(buildApiUrl(`/api/version?t=${(new Date().getTime())}`))
      if(!resp || !resp.data || !resp.data.version)
        throw new Error('Error checking version.')

      commit('setVersion', resp.data.version)
    },
    async updateUser({ commit }, user) {
      await axios.put(buildApiUrl('/api/account'), user)
      commit('loadUser', new UserModel(user))
    },
    async updatePaymentMethod({ dispatch }, user) {
      await axios.put(buildApiUrl('/api/accountpayment'), user)
      Vue.$gtag.event('add_payment_info', { 'currency': 'USD', 'value': 0, 'coupon': user.discountCode, 'items':[ { 'item_id': 'ARA_PREMIUM'}] })
      await dispatch('loadUser')
    },
    async downgradeAccount({ dispatch }, user){
      await axios.put(buildApiUrl('/api/accountdowngrade'), user)
      Vue.$gtag.event('refund', { 'transaction_id': uuidv4() })
      await dispatch('loadUser')
      await dispatch('analyses/clearAnalyses', null, {root:true})
    },
    async upgradeAccount({ dispatch }, user){
      await axios.put(buildApiUrl('/api/accountupgrade'), user)
      Vue.$gtag.event('purchase', { 'currency': 'USD', 'value': 55, 'transaction_id': user.token.id, 'coupon': user.discountCode, 'items':[{'item_id': 'ARA_PREMIUM'}] })
      await dispatch('loadUser')
    },
    async resetPassword(_, parameters) {
      await axios.post(buildApiUrl('/api/reset'), parameters)
    },
    async requestResetPassword(_, parameters) {
      await axios.post(buildApiUrl('/api/requestreset'), parameters)
    },
    sessionTimeoutShown({ commit }) {
      commit('sessionTimeoutShown')
    },
    async logout({ dispatch }) {
      try {
        await axios.post(buildApiUrl('/api/logout'))
      } finally {
        await dispatch('resetState')
      }
    },
    async resetState({ commit, dispatch }) {
      delete axios.defaults.headers.common['Authorization']
      commit('resetState')
      await dispatch('analyses/resetState', null, {root:true})
      await dispatch('taxonomy/resetState', null, {root:true})
      await dispatch('preferences/resetState', null, {root:true})
    },
    async validateSession({ commit, dispatch }) {
      try {
        const resp = await axios.get(buildApiUrl(`/api/validatesession?t=${(new Date().getTime())}`))
        if(!resp || !resp.data)
          throw new Error('Error validating session.')

        commit('setTimeLeft', resp.data)
      } catch(err) {
        await dispatch('resetState')
        throw err
      }
    },
    async keepSessionAlive({ commit, dispatch, state }) {
      if (!state.session) {
        await dispatch('resetState')
        return
      }

      try {
        let result = await axios.post(buildApiUrl('/api/sessions'))
        if(!result || !result.data)
          throw new Error('Error checking session status.')

        commit('setTimeLeft', result.data)
      } catch(err) {
        await dispatch('resetState')
        throw new Error(`Failed to keep session alive (${err.message})`)
      }
    },
    setDirty({ commit }, dirty) {
      commit('setDirty', dirty)
    },
    setGuide({ commit }, guide) {
      return new Promise((resolve) => {
        commit('setGuide', guide)
        resolve()
      })
    },
    setVideoPath({ commit }, path) {
      return new Promise((resolve) => {
        commit('setVideoPath', path)
        resolve()
      })
    },
    clearGuide({ commit }, guide) {
      return new Promise((resolve) => {
        commit('clearGuide', guide)
        resolve()
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.session && !!state.sessionExpiration && DateTime.fromISO(state.sessionExpiration, { zone: 'utc'}) > DateTime.utc(),
    currentUser: state => state.user,
    isUserLoaded: state => !!state.user,
    userType: state => state.user && state.user.tenants.length && state.user.tenants[0] && state.user.tenants[0].type ? state.user.tenants[0].type.toUpperCase() : 'FREE',
    routeAfterLogin: state => state.routeAfterLogin,
  }
})

export default store