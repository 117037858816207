import { Duration } from 'luxon'
import { Weibull } from "@itus/ui.risk"
import objectHash from 'object-hash'

function calculatedComparativeMtbf(scenarioAsset, analysisAsset, taxonomyMap) {
  if(!taxonomyMap || !scenarioAsset || !analysisAsset || !analysisAsset.assetClass || !taxonomyMap[analysisAsset.assetClass])
    return null

  const assetMtbf = scenarioAsset.probabilityFailureDistributionScale
  if (!assetMtbf || !taxonomyMap[analysisAsset.assetClass].mtbf)
    return null

  return (Duration.fromISO(assetMtbf).minus(Duration.fromISO(taxonomyMap[analysisAsset.assetClass].mtbf))).normalize().toISO()
}

function hasValidConsequence(asset) {
  return asset.consequenceValue !== null && asset.consequenceValue !== undefined && !isNaN(parseInt(asset.consequenceValue))
}

function calculateRiskAtMissionDate(startDate, missionTime, mtbf) {
  if (!startDate || !missionTime || !mtbf) {
    return null
  }
  let distributionCalc = new Weibull('local', startDate, missionTime, { type: 'cdf', shape: 1, scale: mtbf })
  return Math.trunc(distributionCalc.calculateRiskAtMissionDate() * 100)
}


function calculateScenarioAssets(scenarioAssets, taxonomyMap, analysisAssets, startDate, missionTime) {
  return scenarioAssets.map(scenarioAsset => {
    let analysisAsset = analysisAssets.find(a => a.assetId === scenarioAsset.assetId)

    scenarioAsset.assetName = analysisAsset.assetName
    scenarioAsset.description = analysisAsset.description
    scenarioAsset.assetClass = analysisAsset.assetClass
    scenarioAsset.assetClassLabel = analysisAsset.assetClassLabel

    scenarioAsset.comparativeMtbf = calculatedComparativeMtbf(scenarioAsset, analysisAsset, taxonomyMap)

    const scenarioMtbf = scenarioAsset.probabilityFailureDistributionScale
    scenarioAsset.probabilityValue = calculateRiskAtMissionDate(startDate, missionTime, scenarioMtbf)
    if (hasValidConsequence(scenarioAsset)) {
      scenarioAsset.consequenceValue = parseInt(scenarioAsset.consequenceValue)
      if (scenarioAsset.probabilityValue === null || scenarioAsset.probabilityValue === undefined) {
        scenarioAsset.risk = null
      } else {
        scenarioAsset.risk = !scenarioAsset.consequenceValue || scenarioAsset.consequenceValue < 0 ? 0 : (scenarioAsset.probabilityValue / 100) * scenarioAsset.consequenceValue
      }
    }

    return scenarioAsset
  })
}

function calculateOpportunity(scenarioCalculatedAssets, scenarioProbabilityThreshold, analysisOpportunity) {
  let oppVal = parseInt(scenarioCalculatedAssets.filter(scenarioCalculatedAsset => scenarioCalculatedAsset.probabilityValue >= scenarioProbabilityThreshold).map(y => parseInt(y.consequenceValue)).reduce(((pv, cv) => pv + cv), 0))
  if(isNaN(oppVal))
    oppVal = 0

  return analysisOpportunity - oppVal
}

function calculateRisk(scenarioCalculatedAssets, analysisRisk) {
  let riskVal = parseInt(scenarioCalculatedAssets.map(scenarioCalculatedAsset => scenarioCalculatedAsset.risk ? parseInt(scenarioCalculatedAsset.risk) : 0).reduce(((pv, cv) => pv + cv), 0))
  if(isNaN(riskVal))
    riskVal = 0
  return analysisRisk - riskVal
}

function scenarioValidityCheck(scenario, analysis, analysisAssets) {
  return (!scenario || !analysis || !analysisAssets) ? false : calculateAnalysisHash(analysis, analysisAssets) === scenario.analysisHash  
}


export function calculateScenario(scenario, analysis, analysisAssets, taxonomyMap, analysisOpportunity, analysisRisk) {
  let scenarioValidForCalculations = scenarioValidityCheck(scenario, analysis, analysisAssets)
  if (scenarioValidForCalculations) {
    //  Clear mission time if there is no end date so that the calcs null out the answers for consistency.
    const missionTime = scenario.scenarioConfiguration.endDate ? scenario.scenarioConfiguration.missionTime : null

    scenario.scenarioConfiguration.assets = calculateScenarioAssets(scenario.scenarioConfiguration.assets, taxonomyMap, analysisAssets, scenario.scenarioConfiguration.startDate, missionTime)
    scenario.potentialSavings = calculateOpportunity(scenario.scenarioConfiguration.assets, scenario.scenarioConfiguration.probabilityThreshold, analysisOpportunity)
    scenario.remainingOpportunity = analysisOpportunity - scenario.potentialSavings
    scenario.riskReduction = calculateRisk(scenario.scenarioConfiguration.assets, analysisRisk)
  }

  scenario.valid = scenarioValidForCalculations
  return scenario
}

export function calculateAnalysisHash(analysis, assets) {
  return objectHash({
    startDate: analysis.startDate,
    missionTime: analysis.missionTime,
    endDate: analysis.endDate,
    probabilityThreshold: analysis.probabilityThreshold !== null && analysis.probabilityThreshold !== undefined ? parseInt(analysis.probabilityThreshold) : analysis.probabilityThreshold,
    assets: assets.map(x => {
      return {
        assetId: x.assetId,
        probabilityValue: x.probabilityValue !== null && x.probabilityValue !== undefined ? parseInt(x.probabilityValue) : x.probabilityValue,
        consequenceValue: x.consequenceValue !== null && x.consequenceValue !== undefined ? parseInt(x.consequenceValue) : x.consequenceValue,
        assetClass: x.assetClass
      }
    })
  }, {
    unorderedArrays: true,
    unorderedSets: true,
    unorderedObjects: true,
    encoding: 'base64'
  })
}