import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=337d94d8&scoped=true&"
import script from "./ProductCard.vue?vue&type=script&lang=js&"
export * from "./ProductCard.vue?vue&type=script&lang=js&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=337d94d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "337d94d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VIcon,VSpacer,VSystemBar})
