import { Convert } from '@itus/ui.common'

export default class ScenarioAssetModel {
	constructor(data) {
		data = data || {}
    this.assetId = Convert.toString(data.assetId, null) 
    this.probabilityValue = Convert.toString(data.probabilityValue, null)
    this.probabilityFailureDistributionScale = Convert.toString(data.probabilityFailureDistributionScale, null)
    this.consequenceValue = Convert.toString(data.consequenceValue, null)
    this.assetClass = Convert.toString(data.assetClass, null)
    this.improve = Convert.toBoolean(data.improve, null)
    this.improveDescription = Convert.toString(data.improveDescription, null)
    this.library = Convert.toBoolean(data.library, null)

    //  Calculated values
    this.assetClassLabel = Convert.toString(data.assetClassLabel, null)
    this.assetName = Convert.toString(data.assetName, null)
    this.comparativeMtbf = Convert.toString(data.comparativeMtbf, null)
    this.risk = Convert.toInteger(data.risk, null)
	}
}