import axios from 'axios'
import { buildApiUrl } from '@/components/api-url-builder'

const getDefaultState = () => {
  return {
    homeExpand: null,
    homeExpandPromise: null,
  }
}

const state = getDefaultState()

// actions
const actions = {
  clearHomeExpand({ commit }) {
    commit('setHomeExpand', null)
    commit('setHomeExpandPromise', null)
  },
  loadHomeExpand({ commit, dispatch }) {
    if (state.homeExpandPromise)
      return state.homeExpandPromise

    dispatch('clearHomeExpand')
    let promise = new Promise((resolve) => {
      axios.get(buildApiUrl('/api/preferences/home-expand'))
        .then(response => {          
          commit('setHomeExpand', response?.data)
        })        
        .finally(() => {
          resolve()
        })
    })

    commit('setHomeExpandPromise', promise)
    return promise
  },
  async updateHomeExpand({ commit }, homeExpandValue) {
    let homeExpand = {
      preferenceName: 'home-expand',
      preferenceValue: homeExpandValue
    }
    await axios.post(buildApiUrl('/api/preferences'), homeExpand)
    commit('setHomeExpand', homeExpand)
  },
  resetState({ commit }) {
    commit('resetState')
  }
}

// mutations
const mutations = {
  setHomeExpand(s, homeExpand) {
    s.homeExpand = homeExpand
  },
  setHomeExpandPromise(s, homeExpandPromise) {
    s.homeExpandPromise = homeExpandPromise
  },
  resetState() {
    //  Reset the state of the store - merge so that we don't lose observers
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}