import { Convert } from '@itus/ui.common'
import ScenarioConfigurationModel from './scenario-configuration-model'

export default class ScenarioModel {
  constructor(data, config) {
    data = data || {}
    this.scenarioId = Convert.toString(data.scenarioId, null)
    this.scenarioName = Convert.toString(data.scenarioName, null)
    this.description = Convert.toString(data.description, null)
    this.analysisHash = Convert.toString(data.analysisHash, null)
    this.scenarioConfiguration = config ? new ScenarioConfigurationModel(config) : new ScenarioConfigurationModel(data.scenarioConfiguration)    

    //  Calculated values
    this.potentialSavings = Convert.toInteger(data.potentialSavings, null)
    this.remainingOpportunity = Convert.toInteger(data.remainingOpportunity, null)
    this.riskReduction = Convert.toInteger(data.riskReduction, null)
    this.valid = Convert.toBoolean(data.valid, null)
  }
}