<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="800"
  >
    <v-card>
      <AlertBar
        v-model="message.show"
        :type="message.type"
        :message="message.text"
        :detail="message.detail"
      ></AlertBar>
      <v-container>
        <template v-if="step === 'info'">
          <v-row class="justify-center align-center">
            <v-col cols="12" class="mt-4">
              <div class="text-center text-h6 font-weight-bold">
                You are currently using the {{tenantType}} version of Itus Risk Analyzer.
              </div>
              <div v-if="tenantType === 'FREE'" class="text-center text-subtitle-1 mt-2">
                <v-icon color="accent" left>fas fa-arrow-circle-up</v-icon>
                Upgrade Risk Analyzer to add analyses and assets
              </div>
              <div class="text-center text-subtitle-1 mt-2">
                <v-icon color="accent" left>fas fa-arrow-circle-up</v-icon>
                Upgrade to Itus APM to mitigate failure risk with Asset Twins
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-right'">
              <ProductCard
                :product="products[0]"
                :price-by="priceBy"
                @product-action="startUpgrade"
                :alreadyPurchased="tenantType !== 'FREE'"
              >
              </ProductCard>
            </v-col>
            <v-col cols="12" sm="6" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'">
              <ProductCard
                :product="products[1]"
                :price-by="priceBy"
                @product-action="contactUs"
              >
              </ProductCard>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn text @click="cancel">Cancel</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else-if="step === 'upgrading'">
          <v-row class="justify-center align-center">
            <v-col cols="12" class="mt-4">
              <div class="text-center text-h6 font-weight-bold">
                Enter your card information below to complete the upgrade.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="12">
                  <div class="pl-1 grey--text">Name: {{fullName}}</div>
                </v-col>
                <v-col cols="12">
                  <div class="pl-1 grey--text">Email: {{user.email}}</div>
                </v-col>
                <v-col cols="12">
                  <stripe-element-card
                    ref="paymentElementRef"
                    :pk="publishableKey"
                    @token="tokenCreated"
                    @element-change="inputChanged"
                    @error="stripeError"
                  />                  
                </v-col>                
                <v-col cols="12">
                  <v-text-field
                    class="InputElement"
                    v-model="discountCode"
                    label="Discount code"
                    hide-details="auto"
                    ref="discountCode"
                    :disabled="!inputIsValid"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn color="primary" class="mr-2" @click="purchase" :disabled="!inputIsValid">Purchase</v-btn>
              <v-btn text @click="stopUpgrade">Cancel</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else-if="step === 'upgraded'">
          <v-row class="justify-center align-center">
            <v-col cols="12" class="mt-4">
              <div class="text-center text-h6 font-weight-bold">
                <v-icon color="success" left>fas fa-check-circle</v-icon>
                Upgrade complete!
              </div>
              <div class="text-subtitle-1 pt-2">
                Thank you for your purchase!  Your account has been upgraded to Itus Risk Analyzer Premium.  You may now close this dialog and begin using the upgraded features of Itus Risk Analyzer Premium.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn text @click="completeUpgrade">Close</v-btn>
            </v-col>
          </v-row>
        </template>

      </v-container>
    </v-card>
    <Busy :value="loading"></Busy>
  </v-dialog>
</template>
<script>
import { Busy, ExceptionMessage, AlertBar } from '@itus/ui.common'
import ProductCard from '@/components/registration/ProductCard'
import Products from '../assets/products.json'
import { mapState } from 'vuex'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import StripeConfig from '../../public/stripe.json'
import { ContactUs } from '@/components/contact-us'

export default {
  name: 'UpgradePremiumDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      loading: false,
      priceBySwitch: true,
      priceBy: 'month',
      products: Products.products,
      publishableKey: StripeConfig.publishableKey,
      inputIsValid: false,
      message: {show:false},
      step: 'info',
      discountCode: ''
    }
  },
  components: {
    Busy,
    ProductCard,
    StripeElementCard,
    AlertBar
  },
  computed: {
     ...mapState({
      user: state => state.user
    }),
    tenantType() {
      return this.user && this.user.tenants.length && this.user.tenants[0] && this.user.tenants[0].type ? this.user.tenants[0].type.toUpperCase() : 'FREE'
    },
    fullName() {
      if (!this.user) {
        return null
      }
      return `${this.user.firstName} ${this.user.lastName}`
    }
  },
  methods: {
    show(contactBaseUrl) {
      if(contactBaseUrl)
        this.contactBaseUrl = contactBaseUrl

      this.message.show = false
      this.step = 'info'

      if (this.$refs.paymentElementRef)
        this.$refs.paymentElementRef.clear()

      this.discountCode = ''

      this.dialog = true

      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    showException(err, defaultMsg) {
      this.message = ExceptionMessage.generateMessage(err, defaultMsg)
    },
    stripeError() {
      if(this.loading)
        this.loading = false
    },
    inputChanged(args){
      if(args.error && this.loading)
        this.loading = false

      this.inputIsValid = args.complete
    },
    async tokenCreated (token) {
      try {
        this.message.show = false
        this.user.token = token
        this.user.discountCode = this.discountCode
        this.loading = true
        await this.$store.dispatch('upgradeAccount', this.user)
        this.step = 'upgraded'
      } catch(err) {
        this.showException(err, 'Error: Unable to upgrade user account.')
      } finally {
        this.loading = false
      }
    },
    contactUs() {
      new ContactUs().go(this.user)
    },
    startUpgrade() {
      this.step = 'upgrading'
    },
    stopUpgrade() {
      this.step = 'info'
      this.message.show = false

      if (this.$refs.paymentElementRef)
        this.$refs.paymentElementRef.clear()

      this.discountCode = ''    
    },
    completeUpgrade() {
      this.dialog = false
      this.resolve('complete')
    },
    purchase() {
      this.loading = true
      this.$refs.paymentElementRef.submit()
    },
    upgradePremium(){
      this.dialog = false
      this.resolve('upgrade')
    },
    cancel() {
      this.dialog = false
      this.resolve('cancel')
    }
  }
}
</script>