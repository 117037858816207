<template>
  <v-card  
    tile
    min-width="275px"
    outlined    
    :class="'product-card ma-4 ' + (product.featured ? 'featured-card' : '')"
  >
    <v-system-bar height="30px" :color="product.featured ? 'primary' : 'transparent'">
      <v-spacer></v-spacer><div class="white--text text-center pa-0 ma-0 text-subtitle-2 font-weight-black">{{product.featured ? 'Most Popular!' : ''}}</div><v-spacer></v-spacer>
    </v-system-bar>
    
    <div class="pt-3 text-center text-h6 primary--text font-weight-bold text-uppercase">
      {{product.name}}
    </div>

    <template v-if="product.priced">
      <div v-if="priceBy === 'month'" class="pt-1 text-center text-h5 font-weight-black">
        ${{product.monthlyPrice}} <span class="text-h6 font-weight-bold grey--text">/ month</span>
      </div>
      <div v-else class="pt-1 text-center text-h4 font-weight-black">
        ${{product.yearlyPrice}} <span class="text-h6 font-weight-bold grey--text">/ year</span>
      </div>
    </template>    
    <div v-else class="pt-1 text-center text-h5 font-weight-black">
      Contact Us
    </div>
    <v-card-text>
      <div class="text-left px-6 text-subtitle-2" v-for="(feature, f) in product.features" :key="f">
        <v-icon x-small class="pr-3" :color="feature.iconColor">{{feature.icon}}</v-icon>
        <span>{{feature.name}}</span>
      </div>
    </v-card-text>
    <v-card-actions class="text-center pb-3">
      <v-spacer></v-spacer>
      <v-btn
        v-if="!alreadyPurchased && product.action" 
        color="accent"
        @click.stop="$emit('product-action', { action: product.action, id: product.id })"
      >
        <v-icon left color="white">fas fa-arrow-circle-up</v-icon>
        {{product.actionText}}
      </v-btn>
      <div style="height:36px" v-else>
        <v-icon left color="success">fas fa-check-circle</v-icon>
        Already Purchased
      </div>      
      <v-spacer></v-spacer>
    </v-card-actions>

  </v-card>

</template>
<script>
export default {
  name: 'ProductCard',
  props: {
    priceBy: {
      type: String,
      description: 'Price by month or year.',
      default: 'month'
    },
    product: {
      type: Object,
      description: 'Product object'
    },
    alreadyPurchased: Boolean
  }
}
</script>
<style lang="scss" scoped>
.product-card {
  min-width:200px;
  max-width:300px;
  display: inline-block;
}

.featured-card {
  border: 1px solid #B3E5FC;
} 

.product-header {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100% !important;
  display: block;
  min-height:22px;
}

.featured-title {
  background-color: #B3E5FC;
  color: #01579B;
}

</style>