import axios from 'axios'
import { TaxonomyModel } from '@/views/apps/analyses/taxonomy-model'
import { buildApiUrl } from '@/components/api-url-builder'

const getDefaultState = () => {
    return {
      taxonomyMap: null,
      taxonomyList: null,
      taxonomyPromise: null
    }
  }
  
  const state = getDefaultState()
  
  // actions
  const actions = {
    clearTaxonomy({ commit }) {
      commit('setTaxonomyMap', null)
      commit('setTaxonomyList', null)
      commit('setTaxonomyPromise', null)
    },
    async loadTaxonomy({ commit, dispatch }) {
      if (state.taxonomyPromise)
        return state.taxonomyPromise
      
      dispatch('clearTaxonomy')
      let promise = axios.get(buildApiUrl('/api/assets/taxonomy/list'))
      commit('setTaxonomyPromise', promise)
      let response = await promise
      let results = response?.data && response.data.length > 0 ? response.data.map(x => new TaxonomyModel(x)) : []   
      let map = {}
      for(let result of results) {
        if(!map[result.id])
          map[result.id] = result
      }
      commit('setTaxonomyMap', map)
      commit('setTaxonomyList', results)
    },
    resetState({ commit }) {
      commit('resetState')
    }
  }
  
  // mutations
  const mutations = {
    resetState() {
      //  Reset the state of the store - merge so that we don't lose observers
      Object.assign(state, getDefaultState())
    },
    setTaxonomyPromise(s, taxonomyPromise) {
      s.taxonomyPromise = taxonomyPromise
    },
    setTaxonomyMap(s, taxonomyMap) {
      s.taxonomyMap = taxonomyMap
    },
    setTaxonomyList(s, taxonomyList) {
      s.taxonomyList = taxonomyList
    }
  }
  
  export default {
    namespaced: true,
    state,
    actions,
    mutations
  }