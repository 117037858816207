import { Convert } from '@itus/ui.common'
import ScenarioAssetModel from './scenario-asset-model'

export default class ScenarioConfigurationModel {
	constructor(data) {
		data = data || {}
    this.startDate = Convert.toString(data.startDate, null) 
    this.missionTime = Convert.toString(data.missionTime, null)
    this.endDate = Convert.toString(data.endDate, null)
    this.probabilityThreshold = Convert.toInteger(data.probabilityThreshold, 50)
    this.initialMTBF = Convert.toString(data.initialMTBF, 'best')
    this.assets = data.assets && data.assets.length ? data.assets.map(x => new ScenarioAssetModel(x)) : []    
	}
}