<template>
  <v-app>
    <v-navigation-drawer app v-model="sideNav" floating right temporary>
      <v-list>
        <template v-for="item in topLevelMenu">
          <v-list-item
            :key="item.id"
            v-if="!item.hasSub && item.visible"
            :to="item.path"
            @click="item.click($event, item)"
          >
            <v-list-item-icon>
              <v-icon left :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
          <v-list-group :key="item.id" v-else :prepend-icon="item.icon">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="subitem in secondLevelMenu">
              <v-divider :key="item.id+subitem.id" v-if="subitem.type === 'divider' && item.id === subitem.parentId && subitem.visible"></v-divider>
              <v-tooltip
                v-else-if="item.id === subitem.parentId && subitem.visible"
                :key="item.id+subitem.id"
                :open-delay="500"
                left
              >
                <template v-slot:activator="{on, attrs}">
                  <v-list-item
                    link
                    :disabled="subitem.disabled"
                    :href="subitem.href"
                    :target="subitem.href ? '_blank' : undefined"
                    @click="subitem.click ? subitem.click($event, subitem) : null"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-list-item-title>{{ subitem.title }}</v-list-item-title>
                    <v-list-item-icon>
                      <v-badge v-if="subitem.badge" overlap :icon="subitem.badge" :color="subitem.badgeColor">
                        <v-icon :color="subitem.disabled ? 'grey lighten-1': subitem.color">{{ subitem.icon }}</v-icon>
                      </v-badge>
                      <v-icon v-else :color="subitem.disabled ? 'grey lighten-1': subitem.color">{{ subitem.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
                <span>{{  subitem.tooltip ? subitem.tooltip : subitem.title  }}</span>
              </v-tooltip>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar elevation="0" app dense>
      <v-toolbar-title>
        <router-link to="/" style="cursor: pointer">
          <v-flex><v-img :src="require('@/assets/itus-logo.svg')" width="133" height="36" :alt="appTitle" position="left center"/></v-flex>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="$store.getters.isUserLoaded">
        <v-toolbar-items>
          <v-tooltip
            bottom
            :open-delay="500"
          >
            <template v-slot:activator="{on, attrs}">
              <v-btn
                tile
                text
                :input-value="undefined"
                @click.stop="upgradeClick"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left color="accent">fas fa-arrow-circle-up</v-icon>
                Upgrade
              </v-btn>
            </template>
            <span>For even more functionality upgrade today!</span>
          </v-tooltip>
          <template v-for="item in topLevelMenu" >
            <v-tooltip
              bottom
              v-if="!item.hasSub && item.visible"
              :key="item.id"
              :open-delay="500"
              :disabled="item.titleInToolbar"
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  class="hidden-sm-and-down"
                  tile
                  text
                  :input-value="undefined"
                  :to="item.path"
                  v-bind="attrs"
                  v-on="on"
                  @click="item.click($event, item)"
                >
                  <v-icon left :color="item.color">{{ item.icon }}</v-icon>
                  <template v-if="item.titleInToolbar">{{ item.title }}</template>
                </v-btn>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>

            <v-menu
              class="hidden-sm-and-down"
              bottom
              offset-y
              v-if="item.hasSub && item.title && item.titleInToolbar && item.visible"
              :key="item.id"
            >
              <template v-slot:activator="{ on }">
                <v-btn class="hidden-sm-and-down" tile text v-on="on">
                  <v-icon left :color="item.color">{{ item.icon }}</v-icon>
                  {{item.title}}
                </v-btn>
              </template>
              <v-list class="hidden-sm-and-down">
                <template v-for="subitem in secondLevelMenu">
                  <v-divider :key="item.id+subitem.id" v-if="subitem.type === 'divider' && item.id === subitem.parentId && subitem.visible"></v-divider>
                  <v-tooltip
                    v-else-if="item.id === subitem.parentId && subitem.visible"
                    :key="subitem.id"
                    :open-delay="500"
                    left
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-list-item
                        class="hidden-sm-and-down"
                        :disabled="subitem.disabled"
                        :href="subitem.href"
                        :target="subitem.href ? '_blank' : undefined"
                        @click="subitem.click ? subitem.click($event, subitem) : null"
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-list-item-icon>
                          <v-badge v-if="subitem.badge" overlap :icon="subitem.badge" :color="subitem.badgeColor">
                            <v-icon left :color="subitem.disabled  ? 'grey lighten-1': subitem.color">{{ subitem.icon }}</v-icon>
                          </v-badge>
                          <v-icon v-else left :color="subitem.disabled  ? 'grey lighten-1': subitem.color">{{ subitem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ subitem.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ subitem.tooltip ? subitem.tooltip : subitem.title }}</span>
                  </v-tooltip>
                </template>
              </v-list>
            </v-menu>
            <v-menu
              class="hidden-sm-and-down"
              bottom
              offset-y
              v-if="item.hasSub && (!item.title || !item.titleInToolbar) && item.visible"
              :key="item.id"
            >
              <template v-slot:activator="{ on: menu }">
                <v-tooltip
                  bottom
                  :open-delay="500"
                  :disabled="item.titleInToolbar"
                >
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn class="hidden-sm-and-down" rounded text icon v-on="{ ...tooltip, ...menu }">
                      <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>
              </template>
              <v-list class="hidden-sm-and-down">
                <template v-for="subitem in secondLevelMenu">
                  <v-divider :key="item.id+subitem.id" v-if="subitem.type === 'divider' && item.id === subitem.parentId && subitem.visible"></v-divider>
                  <v-tooltip
                    v-else-if="item.id === subitem.parentId && subitem.visible"
                    :key="subitem.id"
                    :open-delay="500"
                    left
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-list-item
                        class="hidden-sm-and-down"
                        :disabled="subitem.disabled"
                        :href="subitem.href"
                        :target="subitem.href ? '_blank' : undefined"
                        @click="subitem.click ? subitem.click($event, subitem) : null"
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-list-item-icon>
                          <v-badge v-if="subitem.badge" overlap :icon="subitem.badge" :color="subitem.badgeColor">
                            <v-icon left :color="subitem.disabled  ? 'grey lighten-1': subitem.color">{{ subitem.icon }}</v-icon>
                          </v-badge>
                          <v-icon v-else left :color="subitem.disabled  ? 'grey lighten-1': subitem.color">{{ subitem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ subitem.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ subitem.tooltip ? subitem.tooltip : subitem.title }}</span>
                  </v-tooltip>

                </template>
              </v-list>
            </v-menu>

        </template>
        <v-menu
          bottom
          offset-y
        >
          <template v-slot:activator="{ on: menu }">
            <v-tooltip
              bottom
              :open-delay="500"
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-btn rounded text icon v-on="{ ...tooltip, ...menu }">
                  <UserAvatar
                    :firstName="firstName"
                    :lastName="lastName"
                    :size="36"
                  ></UserAvatar>
                </v-btn>
              </template>
              Account
            </v-tooltip>
          </template>
          <v-card flat tile>
            <v-card-text class="text-center">
              <v-row>
                <v-col cols="12">
                  <UserAvatar
                    :firstName="firstName"
                    :lastName="lastName"
                    :size="80"
                  ></UserAvatar>
                </v-col>
                <v-col cols="12">
                  {{fullName}}
                  <br/>
                  {{user.email}}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-list>
            <v-list-item
              key="account"
              @click="$router.push('/account')"
            >
              <v-list-item-title>
                <v-icon left color="primary">person</v-icon>
                Manage your account
              </v-list-item-title>
            </v-list-item>

              <v-list-item
                key="signout"
                @click.capture.stop="signOut"
                @mousedown.stop
              >
                <v-list-item-title>
                  <v-icon left color="primary">exit_to_app</v-icon>
                  Sign out
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click.native.stop="sideNav = !sideNav"
        >
        </v-app-bar-nav-icon>
      </template>

    </v-app-bar>
    <v-main style="width:100%;height:calc(100% - 70px);">
      <router-view class="pa-0 ma-0" :key="$route.fullPath" ></router-view>
      <SessionTimeoutDialog ref="sessionTimeoutDialog"></SessionTimeoutDialog>
      <NewVersionAvailableDialog ref="newVersionAvailableDialog"></NewVersionAvailableDialog>
      <Confirm ref="confirm"></Confirm>
      <UpgradeDialog ref="upgradeDialog"></UpgradeDialog>
    </v-main>
    <VideoDialog
      v-model="showGuide"
      :videos="guide"
      @close="closeGuide"
    ></VideoDialog>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import { AssetUtility, Confirm, NewVersionAvailableDialog, SessionTimeoutDialog, UserAvatar, VideoDialog } from '@itus/ui.common'
import Version from '../public/version.json'
import UpgradeDialog from '@/components/UpgradeDialog'

const oneMinuteMS = 60000
const countdownLimit = oneMinuteMS;
const keepAliveLimit = oneMinuteMS * 5
const versionCheckTime = oneMinuteMS * 10
const sleepResumeCheckTime = 15000

export default {
  name: 'App',
  data () {
    return {
      sideNav: false,
      appTitle: 'Itus Digital',
      untilCountdownTimer: null,
      untilKeepAliveTimer: null,
      canKeepAlive: false,
      countdownShown: false,
      versionTimer: null,
      loadedVersion: Version.version,
      showGuide: false,
      twinIcon: AssetUtility.TWIN_ICON,
      sleepResumeTimer: null
    }
  },
  components: {
    Confirm,
    NewVersionAvailableDialog,
    SessionTimeoutDialog,
    UpgradeDialog,
    UserAvatar,
    VideoDialog
  },
  computed: {
    ...mapState({
      user: state => state.user,
      session: state => state.session,
      sessionExpiration: state => state.sessionExpiration,
      dirty: state => state.dirty,
      guide: state => state.guide,
      version: state => state.version
    }),
    fullName() {
      if (!this.user) {
        return null
      }
      return `${this.user.firstName} ${this.user.lastName}`
    },
    firstName() {
      return this.user?.firstName
    },
    lastName() {
      return this.user?.lastName
    },
    guideAvailable() {
      return !!this.guide && this.guide.length > 0
    },
    topLevelMenu(){
      return [
        { id: 'apps', title: 'Apps', icon: 'apps', color: 'primary', hasSub: true, titleInToolbar: false, visible: true },
        { id: 'help', title: 'Help', icon: 'help', color: 'primary', hasSub: true, titleInToolbar: false, visible: true }
      ]
    },
    secondLevelMenu() {
      return [
        { parentId: 'apps', id: 'analyses', title: 'Risk Analyzer', click: this.navigate, path: '/apps/analyses', icon: 'fas fa-exclamation-triangle', color: 'primary', disabled: false, visible: true, tooltip:"Risk Analyzer" },
        { parentId: 'apps', id: 'twins', title: 'Twins', click: this.navigate, path: '/apps/twins', icon: this.twinIcon, color: 'primary', badge:'fas fa-arrow-circle-up accent--text', badgeColor: 'transparent', disabled: false, visible: true, tooltip:"This feature is available in Itus APM - click here to learn more." },
        { parentId: 'apps', id: 'advisories', title: 'Advisories', click: this.navigate, path: '/apps/advisories', icon: 'fa-comment-medical', color: 'primary', badge:'fas fa-arrow-circle-up accent--text', badgeColor: 'transparent', disabled: false, visible: true , tooltip:"This feature is available in Itus APM - click here to learn more." },
        { parentId: 'apps', id: 'library', title: 'Twin Library', click: this.navigate, path: '/apps/library', icon: 'fas fa-books', color: 'primary', badge:'fas fa-arrow-circle-up accent--text', badgeColor: 'transparent', disabled: false, visible: true , tooltip:"This feature is available in Itus APM - click here to learn more." },
        { parentId: 'apps', id: 'tools_divider', type: 'divider', visible: true },
        { parentId: 'apps', id: 'dashboard', title: 'Insights', click: this.navigate, path: '/dashboard', icon: 'dashboard', color: 'primary', badge:'fas fa-arrow-circle-up accent--text', badgeColor: 'transparent', disabled: false, visible: true, tooltip:"This feature is available in Itus APM - click here to learn more."  },
        { parentId: 'help', id: 'documentation', href: '/Asset Risk Analyzer - Working with your first Analysis.pdf', title: 'Documentation', icon: 'chrome_reader_mode', color: 'primary', disabled: false, download: true, visible: true },
        { parentId: 'help', id: 'guide', title: 'Guide Me', click: this.guideClick, icon: 'fas fa-map-signs', color: 'primary', disabled: false, visible: this.guideAvailable },
        { parentId: 'help', id: 'support', click: this.navigate, path: '/support', title: 'Support', icon: 'fas fa-life-ring', color: 'primary', disabled: false, download: true, visible: true }
      ]
    }
  },
  watch: {
    session: {
      handler(newValue) {
        if(newValue) {

          if(!this.versionTimer)
            this.versionTimer = setInterval(this.versionCheck, versionCheckTime)

          if(!this.sleepResumeTimer)
            this.sleepResumeTimer = setInterval(this.sleepResumeCheck, sleepResumeCheckTime)

        } else if (!newValue) {

          if(this.versionTimer)
            clearInterval(this.versionTimer)

          if(this.sleepResumeTimer)
            clearInterval(this.sleepResumeTimer)

          this.versionTimer = null
          this.sleepResumeTimer = null
        }
      },
      immediate: true
    },
    sessionExpiration: {
      handler() {
        let timeLeftMS = this.getTimeLeftMS()
        if (!timeLeftMS)
          return

        if (timeLeftMS > countdownLimit) {
          if (this.untilCountdownTimer) {
            clearTimeout(this.untilCountdownTimer)
          }
          this.untilCountdownTimer = setTimeout(this.oneMinuteLeft, timeLeftMS - countdownLimit)
        } else {
          this.startCountdown()
        }

        if (timeLeftMS > keepAliveLimit) {
          this.canKeepAlive = false
          if (this.untilKeepAliveTimer) {
            clearTimeout(this.untilKeepAliveTimer)
          }
          this.untilKeepAliveTimer = setTimeout(() => this.canKeepAlive = true, timeLeftMS - keepAliveLimit)
        } else {
          this.canKeepAlive = true
        }
      },
      immediate: true
    }
  },
  methods: {
    guideClick() {
      this.showGuide = true
    },
    closeGuide() {
      this.showGuide = false
    },
    async upgradeClick() {
      await this.$refs.upgradeDialog.show()
    },
    getTimeLeftMS() {
      let expirationDateTime = DateTime.fromISO(this.sessionExpiration, { zone: 'utc'})
      if (expirationDateTime.invalid)
        return null

      let timeLeftDuration = expirationDateTime.diff(DateTime.utc())
      return timeLeftDuration.as('milliseconds')
    },
    async oneMinuteLeft() {
      this.untilCountdownTimer = null
      try {
        await this.$store.dispatch('validateSession')
        await this.startCountdown()
      } catch {
        this.signOut()
      }
    },
    async startCountdown() {
      // session is about to expire - prompt for continuation
      let timeLeftMS = this.getTimeLeftMS()
      if (this.countdownShown || timeLeftMS == null || timeLeftMS > countdownLimit)
        return

      if(!this.$refs.sessionTimeoutDialog) {
        this.signOut()
      } else {
        this.countdownShown = true
        let response = await this.$refs.sessionTimeoutDialog.show({ dirty: this.dirty })
        this.countdownShown = false
        if (response === 'extend') {
          try {
            await this.$store.dispatch('keepSessionAlive')
          } catch {
            this.signOut()
          }
        }
        else {
          this.signOut()
        }
      }
    },
    keepAliveCheck() {
      //  If we're under the keep alive limit and we have not started the countdown.
      //  (untilCountdownTimer will be null once the countdown is started)
      if (this.canKeepAlive && this.untilCountdownTimer)
        this.$store.dispatch('keepSessionAlive')
          .catch(() => this.signOut())
    },
    signOut() {
      if (this.untilCountdownTimer)
        clearTimeout(this.untilCountdownTimer)

      if (this.untilKeepAliveTimer)
        clearTimeout(this.untilKeepAliveTimer)

      if (this.$refs.confirm && this.$refs.confirm.dialog) {
        if (this.$refs.confirm.resolve) {
          this.$refs.confirm.resolve(false)
        }
        this.$refs.confirm.dialog = false
      }

      this.$router.push('/signout')
    },
    sleepResumeCheck() {
      if(!this.$router.currentRoute.meta.requiresAuth)
        return

      if(DateTime.fromISO(this.sessionExpiration, { zone: 'utc'}) <= DateTime.utc())
        this.signOut()
    },
    async versionCheck() {
      await this.$store.dispatch('checkCurrentVersion')
      if(this.version !== this.loadedVersion) {
        let response = await this.$refs.newVersionAvailableDialog.show({ dirty: this.dirty })
        if(response)
          window.location.assign(window.location)
      }
    },
    navigate(_, item) {
      this.$router.push(item.path)
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
    this.$el.addEventListener('mousedown', this.keepAliveCheck)
    this.$el.addEventListener('keydown', this.keepAliveCheck)
  },
  beforeDestroy() {
    this.$el.removeEventListener('mousedown', this.keepAliveCheck)
    this.$el.removeEventListener('keydown', this.keepAliveCheck)
    if(this.versionTimer) {
      clearInterval(this.versionTimer)
      this.versionTimer = null
    }
    if(this.sleepResumeTimer) {
      clearInterval(this.sleepResumeTimer)
      this.sleepResumeTimer = null
    }
  }
}
//'../node_modules/typeface-roboto/index.css',
// /@import '../node_modules/@fontsource/material-icons/index.css'
</script>
<style lang="sass">
  
</style>
<style>
  html {
    overflow-y: auto !important
  }
</style>
<style lang="scss" scoped>
.theme--light.v-btn--active:before {
  opacity: 0;
}
</style>
