import { Convert } from '@itus/ui.common'

export class UserModel {
    constructor(data) {
        data = data || {}
        this.email = Convert.toString(data.email, null)
        this.firstName = Convert.toString(data.firstName, null)
        this.lastName = Convert.toString(data.lastName, null)
        this.tenants = data.tenants || []
        this.eula = Convert.toString(data.eula, null)
        this.paymentMethod = data.paymentMethod || {}
    }
}
export default { UserModel }